@import "./variables.scss";

/* main styles */
body {
  margin: 0;
  background: $white;
  font-family: $font;
  font-size: 16px;
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
}
#main-grid {
  height: 100%;
}
hr {
  border-top: 1px solid rgba(0, 0, 0, 0.23);
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.padding-20 {
  padding: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-top-20 {
  padding-top: 20px;
}

button.light-button {
  background-color: $white;
}
.toolbar {
  background-color: $grey;
}
/* login form */
#login-form {
  padding-top: 7%;
  .login-form-logo {
    height: 80px;
    text-align: center;
  }
  h3 {
    margin-bottom: 10px;
  }
  button {
    background: $primary;
    color: $white;
  }
}
/* form */
#edit-form {
  h3 {
    margin-bottom: 20px;
  }
  // .MuiInput-root {
  //   margin: 10px 10px 10px 0;
  // }
}

.tox-tinymce {
  border-radius: 4px;
  width: 100%;
  margin-left: 8px;
}
/* header */
header .MuiTypography-h5 {
  color: $white;
}
/* menu */
#menu-container {
  background-color: $grey;
  a {
    color: $white;
    text-decoration: none;
  }
  .MuiListItem-button path {
    fill: $white;
  }
  .MuiCollapse-container .MuiListItem-button {
    padding-left: 40px;
    background-color: $secondary;
  }
}
/* main container */
#main-container {
  padding: 20px;
}
.image-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  padding-top: 10px;

  .MuiTypography-body2 svg {
    vertical-align: middle;
  }
}
